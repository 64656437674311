<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="车辆选择">
                <a-tree-select
                  v-model="deviceIds"
                  :replaceFields="replaceFields"
                  show-search
                  style="width: 100%"
                  :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                  placeholder="模糊搜索，可搜索自编号或车牌号"
                  allow-clear
                  tree-default-expand-all
                  :tree-data="vehicleTreeData"
                  tree-checkable
                  :show-checked-strategy="SHOW_PARENT"
                  @change="handleVehicleNoChange"
                  :maxTagCount="2"
                  class="alarm-search"
                  treeNodeFilterProp="title"
                >
                </a-tree-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="报警类型">
                <a-select default-value="" v-model="queryParam.warnType">
                  <a-select-option value=""> 全部 </a-select-option>
                  <a-select-option :value="item.value" v-for="item in warnTypeOptions" :key="item.value">
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <template v-if="advanced"> </template>
            <a-col :md="(!advanced && 8) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <a-toolbar :loading="loading" refresh @refreshQuery="getList" tid="1">
        <div class="table-operations">
          <a-button
            type="primary"
            @click="$refs.fenceForm.handleAdd()"
            v-hasPermi="['tenant:jt808:warningRuleArea:add']"
          >
            <a-icon type="plus" />新增电子围栏
          </a-button>
          <a-button
            type="primary"
            @click="$refs.OverSpeedForm.handleAdd()"
            v-hasPermi="['tenant:jt808:warningRuleSpeeding:add']"
          >
            <a-icon type="plus" />新增超速报警
          </a-button>
          <a-button
            type="primary"
            @click="$refs.ForbidenZoneForm.handleAdd()"
            v-hasPermi="['tenant:jt808:warningRuleForbiddenArea:add']"
          >
            <a-icon type="plus" />新增禁入区域
          </a-button>
          <a-button
            type="primary"
            @click="$refs.ParkingViolationForm.handleAdd()"
            v-hasPermi="['tenant:jt808:warningRuleParkingTimeout:add']"
          >
            <a-icon type="plus" />新增停车超时
          </a-button>
          <!-- <a-button
            type="dashed"
            shape="circle"
            :loading="loading"
            :style="{ float: 'right' }"
            icon="reload"
            @click="getList"
          /> -->
        </div>
      </a-toolbar>
      <!-- 超速报警设置增加修改 -->
      <over-speed-form ref="OverSpeedForm" @ok="getList" />
      <!-- 电子围栏增加修改 -->
      <fence-form ref="fenceForm" @ok="getList" />
      <!-- 禁入区域增加修改 -->
      <forbiden-zone-form ref="ForbidenZoneForm" @ok="getList" />
      <!-- 超时增加修改 -->
      <parking-violation-form ref="ParkingViolationForm" @ok="getList" />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        tid="1"
        :columns="columns"
        :data-source="list"
        :pagination="false"
      >
        <span slot="operation" slot-scope="text, record">
          <a @click="handleUpdate(record)"> <a-icon type="edit" />修改 </a>
          <a-divider type="vertical" />
          <a @click="handleDelete(record)"> <a-icon type="edit" />删除 </a>
          <a-divider type="vertical" />
          <a @click="handleBrowse(record)"> <a-icon type="info-circle" />查看 </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="(total) => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import debounce from 'lodash/debounce'
import { listWarningRule, delWarningRule } from '@/api/jt808/warningRule'
import OverSpeedForm from './modules/overSpeed'
import FenceForm from './modules/fence'
import ForbidenZoneForm from './modules/forbidenZone'
import ParkingViolationForm from './modules/parkingViolation'
import { TreeSelect } from 'ant-design-vue'
import { deviceTree } from '@/api/iot/device'
export default {
  name: 'AreaManager',
  components: {
    OverSpeedForm,
    ForbidenZoneForm,
    ParkingViolationForm,
    FenceForm,
    ATreeSelect: TreeSelect
  },
  data() {
    this.handleVehicleNoSearch = debounce(this.handleVehicleNoSearch, 500)
    return {
      replaceFields: { children: 'children', title: 'label', key: 'id', value: 'id' },
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        warnType: null,
        deviceIds: null,
        pageNum: 1,
        pageSize: 10
      },
      deviceIds: [],
      warnTypeOptions: [
        {
          label: '电子围栏',
          value: 1
        },
        {
          label: '超速报警',
          value: 2
        },
        {
          label: '禁入区域',
          value: 3
        },
        {
          label: '停车超时',
          value: 4
        }
      ],
      columns: [
        {
          title: '报警类型',
          dataIndex: 'warnType',
          ellipsis: true,
          align: 'center',
          customRender: (text, record, index) => {
            const obj = this.warnTypeOptions.find((p) => p.value === record.warnType)
            return obj ? obj.label : ''
          }
        },
        {
          title: '车辆(车牌号|自编号)',
          dataIndex: 'vehicles',
          ellipsis: true,
          align: 'center',
          customRender: (text, record, index) => {
            if (!record.vehicleNo) {
              return ''
            }
            let val = record.plateNo + '|' + record.vehicleNo
            if (record.vehicleCount > 1) {
              val += '等' + record.vehicleCount + '辆'
            }
            return val
          }
        },
        {
          title: '备注',
          dataIndex: 'remark',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '报警防抖时延(秒)',
          dataIndex: 'antiShake',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '合法停车时长(分钟)',
          dataIndex: 'limitTime',
          ellipsis: true,
          align: 'limitTime',
          customRender: (text, record, index) => {
            return record.warnType === 4 ? record.limitTime : '-'
          }
        },
        {
          title: '开关',
          dataIndex: 'onOff',
          ellipsis: true,
          align: 'center',
          customRender: (text, record, index) => {
            return record.onOff ? '开' : '关'
          }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],
      vehicleTreeData: []
    }
  },
  filters: {},
  created() {
    this.getList()
    this.handleVehicleNoSearch()
  },
  computed: {},
  watch: {},
  methods: {
    /** 查询sim卡管理列表 */
    getList() {
      this.loading = true
      this.queryParam.params = {}
      if (this.deviceIds) {
        this.queryParam.deviceIds = this.deviceIds.map((p) => (p.indexOf('t-1-') >= 0 ? p.substr(4) : p))
      }
      listWarningRule(this.queryParam).then((response) => {
        this.list = response.rows
        this.total = response.total
        this.loading = false
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        name: undefined,
        deptId: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    handleVehicleNoSearch(value) {
      console.log('handleVehicleNoSearch', value)
      const formData = new FormData()
      formData.append('deviceType', 1)
      formData.append('bindStatus', 'bind')
      if (value && value.trim()) {
        formData.append('bindName', value.trim())
      }
      deviceTree(formData).then((response) => {
        this.vehicleTreeData = response.data
      })
    },
    handleVehicleNoChange(value) {
      console.log('value', value)
    },
    handleUpdate(record) {
      if (record.warnType === 1) {
        this.$refs.fenceForm.handleUpdate(record, undefined)
      } else if (record.warnType === 2) {
        this.$refs.OverSpeedForm.handleUpdate(record, undefined)
      } else if (record.warnType === 3) {
        this.$refs.ForbidenZoneForm.handleUpdate(record, undefined)
      } else if (record.warnType === 4) {
        this.$refs.ParkingViolationForm.handleUpdate(record, undefined)
      }
    },
    handleDelete(record) {
      this.$confirm({
        title: '确认要删除这条记录吗?',
        onOk: () => {
          delWarningRule(record.id).then((response) => {
            this.getList()
            this.loading = false
          })
        },
        onCancel() {}
      })
    },
    handleBrowse(record) {
      if (record.warnType === 1) {
        this.$refs.fenceForm.handleUpdate(record, undefined, 'read')
      } else if (record.warnType === 2) {
        this.$refs.OverSpeedForm.handleUpdate(record, undefined, 'read')
      } else if (record.warnType === 3) {
        this.$refs.ForbidenZoneForm.handleUpdate(record, undefined, 'read')
      } else if (record.warnType === 4) {
        this.$refs.ParkingViolationForm.handleUpdate(record, undefined, 'read')
      }
    }
  }
}
</script>

<style lang="less">
.alarm-search {
  .ant-select-selection--multiple {
    max-height: 32px !important;
    overflow: hidden;
  }
}
</style>
